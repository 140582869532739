import useAuth from './useAuth'
import {useCallback, useEffect, useState} from 'react'
import {sleep} from '../components/util/Language'

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
const port = window.location.port

export default function useWebSocket(path, callback) {
  const {accessToken, refreshAuth} = useAuth()
  const [connected, setConnected] = useState()
  const [websocket, setWebsocket] = useState()

  const reconnect = useCallback(async (refresh) => {
    setConnected(false)
    await sleep(1000)
    if (refresh) await refreshAuth()
  }, [])

  const startWebsocket = useCallback(() => {
    const url = `${protocol}://${window.location.hostname}:${port}${path}${path.includes('?') ? '&' : '?'}access_token=${accessToken}`
    try {
      const webSocket = new WebSocket(url)
      webSocket.onmessage = it => it.data !== 'pong' && callback(it)
      webSocket.onerror = () => reconnect(false)
      webSocket.onclose = ({reason}) => {
        console.info('Closed:', path, reason)
        return reconnect(reason === 'Not Authorized')
      }
      webSocket.onopen = () => setConnected(true)
      return webSocket
    } catch (e) {
      reconnect(false)
    }
  }, [path, reconnect, accessToken])

  useEffect(() => {
    if (!path || !accessToken) return
    console.info('Opening:', path)
    const websocket = startWebsocket()
    websocket.randomId = Math.random()
    setWebsocket(websocket)
    const interval = setInterval(() => websocket?.readyState === 1 && websocket.send('ping'), 8000)

    return () => {
      console.info('Closed:', path)
      try {
        clearInterval(interval)
        websocket && websocket.close()
      } catch (e) {
      }
    }
  }, [path, accessToken])

  return {connected, websocket}
}
