import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import useWebSocket from '../hooks/useWebSocket'
import {prepareDto} from '../api/Parsers'
import {isArray, keys, uniq} from 'lodash'
import useAuth from '../hooks/useAuth'

export const RefDataContext = React.createContext({})

export const RefDataProvider = ({children}) => {
  const refDataRef = useRef({})
  const {entityId} = useAuth()

  const keyForType = (type, value) => {
    if (type === 'universe') return value.datasetString
    if (type === 'ledgers') return value.ownerId
    if (value.id) return value.id
    return type
  }

  function createAndGetTarget(type) {
    if (!refDataRef.current[type]) {
      refDataRef.current[type] = {values: {}}
    }
    return refDataRef.current[type]
  }

  ['runs', 'strategies', 'funds'].forEach(createAndGetTarget)

  function processValue(type, value, target) {
    const key = keyForType(type, value)
    if (!value.delete) {
      target.values[key] = value
    } else {
      delete target.values[key]
    }
  }

  const {websocket, connected} = useWebSocket('/ws/infra/refdata', ({data}) => {
    const {type, value: valueRaw} = JSON.parse(data)
    const target = createAndGetTarget(type)
    if (valueRaw === 'finished') {
      target.finished = true
    } else {
      const value = prepareDto(type, valueRaw)
      if (isArray(value)) {
        value.forEach(it => processValue(type, it, target))
      } else {
        processValue(type, value, target)
      }
    }
    // If we've received the initial then we increase the updated value.
    if (target.finished) {
      refDataRef.current.updated = target.updated = +new Date()
    }
  })

  const getRequestedTypes = () => uniq(keys(refDataRef.current).filter(it => it !== 'updated'))

  const request = (...newTypes) => {
    if (websocket?.readyState !== 1) return
    const typesBefore = getRequestedTypes().join(',')
    newTypes.forEach(createAndGetTarget)
    const typesAfter = getRequestedTypes().join(',')
    if (typesBefore !== typesAfter) websocket.send(`types:${typesAfter}`)
  }

  useEffect(() => {
    if (websocket?.readyState !== 1) return
    const types = getRequestedTypes()
    refDataRef.current = {}
    request(...types)
  }, [websocket?.randomId])

  const webSocketId = connected ? websocket.randomId : undefined
  return (
    <RefDataContext.Provider value={{request, refDataRef, webSocketId}}>
      {children}
    </RefDataContext.Provider>
  )
}

export const useRefData = (...types) => {
  const {request, refDataRef, webSocketId} = useContext(RefDataContext)
  const [{myRefData, updateTimes}, setMyRefDataAndTimes] = useState({myRefData: {}, updateTimes: {}})
  const typesString = types.join(',')

  useEffect(() => {
    if (!types || types.length === 0) return
    request(...types)
  }, [webSocketId, typesString])

  const checkMyData = useCallback(() => {
    if (!types || types.length === 0) return
    if (!refDataRef.current.updated || refDataRef.current.updated <= updateTimes.updated) return
    types.forEach(type => {
      const target = refDataRef.current[type] || {}
      if (!target.updated || target.updated <= updateTimes[type]) return

      setMyRefDataAndTimes(({myRefData, updateTimes}) => ({
        myRefData: {...myRefData, [type]: target.values},
        updateTimes: {...updateTimes, [type]: target.updated, updated: refDataRef.current.updated},
      }))
    })
  }, [typesString, myRefData, updateTimes, setMyRefDataAndTimes])

  useEffect(() => {
    let callback = setInterval(checkMyData, 100)
    return () => clearInterval(callback)
  }, [typesString, checkMyData])

  const ready = types.every(type => !!myRefData?.[type])

  return {ready, ...(myRefData || {})}
}
