import axiosImported from 'axios'
import moment from 'moment'
import React, {createContext, useEffect, useState} from 'react'

export const ClientContext = createContext(null)

export const ClientProvider = ({children}) => {
  const [{token, refreshToken}, _setTokens] = useState({
    token: localStorage.getItem('jwt'),
    refreshToken: localStorage.getItem('jwt-refresh'),
  })
  const axios = axiosImported.create()

  const setTokens = (newToken, newRefreshToken) => {
    localStorage.setItem('jwt', newToken)
    localStorage.setItem('jwt-refresh', newRefreshToken)
    _setTokens({token: newToken, refreshToken: newRefreshToken})
  }

  const authenticate = async (username, password, entityId) => {
    const {data: {access_token, refresh_token}} = await axios.post('/api/auth/login', {username, password, entityId})
    setTokens(access_token, refresh_token)
    return access_token
  }

  const clearTokens = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('jwt-refresh')
    _setTokens({token: null, refreshToken: null})
  }

  const ParamsSerializer = {
    indexes: null,
    encode: param => param instanceof moment ? param.toISOString() : param,
  }

  axios.interceptors.request.use(
    (config) => {
      if (token) config.headers.Authorization = `Bearer ${token}`
      config.paramsSerializer = ParamsSerializer
      return config
    },
    (error) => Promise.reject(error),
  )

  const handleReject = async function (error) {
    const originalRequest = error.config
    // We only want to retry if the error is 401.
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error)
    }
    // If we already tried refreshing the token, don't do it again.
    if (originalRequest._retry) {
      return Promise.reject(error)
    }
    // Retry the request
    return await retryRequest(originalRequest, error)
  }

  const useRefreshToken = async (entityId) => {
    console.info('useRefreshToken', entityId)
    if (!refreshToken) return Promise.reject('No refresh token')
    const post = {refresh_token: refreshToken, grant_type: 'refresh_token', entityId}
    const {data: {access_token}} = await axiosImported.post('/api/auth/oauth/access_token', post)
    setTokens(access_token, refreshToken)
    return access_token
  }

  const retryRequest = async (originalRequest, error) => {
    originalRequest._retry = true
    try {
      const accessToken = await useRefreshToken()
      axiosImported.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      return axios(originalRequest)
    } catch (e) {
      console.info('retryRequest: failed to get new token: ' + e)
      throw error
    }
  }

  axios.interceptors.response.use((response) => response, handleReject)

  return (
    <ClientContext.Provider value={{axios, token, useRefreshToken, clearTokens, authenticate}}>
      {children}
    </ClientContext.Provider>
  )
}
