import {useContext} from 'react'
import {get} from 'lodash'
import {AuthContext} from '../context/AuthProvider'

export default function useAuth() {
  const {auth, accessToken, setUser, loginUser, refreshAuth, logoutUser} = useContext(AuthContext)
  const isLoading = auth === 'pending'
  const entityId = get(auth, 'attributes.entityId')
  return {auth, entityId, isLoading, accessToken, refreshAuth, setUser, loginUser, logoutUser}
}
