import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useAltoClient from '../api/UseAltoClient'

export const AuthContext = React.createContext({})

const PENDING = 'pending'

const AuthProvider = ({children}) => {
  const [auth, setAuth] = useState(PENDING)
  const {whoAmI, logout, authenticate, useRefreshToken, accessToken} = useAltoClient()
  const history = useHistory()

  useEffect(() => {
    if (accessToken === null) {
      setAuth(undefined)
      return
    }
    whoAmI().then(setAuth).catch(() => setAuth())
  }, [accessToken])

  // We just logged in, redirect to home page.
  useEffect(() => {
    if (history.location.pathname === '/login' && auth && auth !== PENDING) {
      history.push('/')
    }
  }, [auth])

  const loginUser = async ({username, password, entityId}) => {
    await authenticate(username, password, entityId)
  }

  const refreshAuth = async (entityId) => {
    setAuth(PENDING)
    await useRefreshToken(entityId)
  }

  const logoutUser = async () => {
    setAuth(undefined)
    await logout()
    history.push('/login')
  }

  const accessTokenIfAuth = auth?.name ? accessToken : undefined
  return (
    <AuthContext.Provider value={{auth, accessToken: accessTokenIfAuth, refreshAuth, loginUser, logoutUser}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
